















































/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState } from 'vuex';
import PageMain from '@part/layout/PageMain.vue';
import lang from '@lang/settings.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
    },
    props: [],
    data() {
        return {
            pageData: {},
            required: {
                // product_type: false,
                // user_type: false
            }
        };
    },
    computed: {
        lang(){
            return lang[this.activeLang()];
        },
        ...mapState(['sessionData']),
        filled() {
            let filledIn = true
            for (let key in this.required) {
                !this.required[key] && (filledIn = false)
            }
            return filledIn;
        }
    },
    methods: {
    },
    mounted() {
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}

export default {
    en: {
        sock: 'SOCK',
        scarf: 'SCARF',
        user: 'USER',
        single: 'SINGLE',
        group: 'GROUP',
        button: 'SAVE'
    },
    nl: {
        sock: 'SOK',
        scarf: 'SJAAL',
        user: 'GEBRUIKER',
        single: 'ALLEEN',
        group: 'GROEP',
        button: 'OPSLAAN'
    }
};
